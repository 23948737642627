import React from 'react';
import Dropdown from './Dropdown';

export default class AccountRole extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        selectedDuration: "" + ((this.props.role || {}).defaultDuration || 3600)
      };
    }

    render() {
        const accountId = this.props.accountId;
        const role = this.props.role || {};
        const roleName = role.name;
        const getConsoleHandler = this.props.getConsoleHandler;
        const getCreadentialsHandler = this.props.getCreadentialsHandler;

        const availableOptions = [
            { label: '⏱ 1H', value: '3600' }
        ];
        if (7200 <= parseInt(role.maxDuration, 10)) {
            availableOptions.push({ label: '⏱ 2H', value: '7200' });
        }
        if (14400 <= parseInt(role.maxDuration, 10)) {
            availableOptions.push({ label: '⏱ 4H', value: '14400' });
        }

        return (
            <li key={roleName} class="account-role-item">
            <span>{roleName}</span>
            <Dropdown
                options={availableOptions}
                value={this.state.selectedDuration}
                onChange={(event) => this.setState({selectedDuration: event.target.value})}
            />
            <a href='#' onClick={(event) => getConsoleHandler(event, {
                accountId: accountId, 
                roleName: roleName,
                duration: this.state.selectedDuration
            })}>console access</a>
            <a href='#' onClick={(event) => getCreadentialsHandler(event, {
                accountId: accountId, 
                roleName: roleName,
                duration: this.state.selectedDuration
            })}>temporary credentials</a>
            </li>
        );
    }
};