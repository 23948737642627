import React from 'react';

const TermsPage = function() {
    return (<div>
      <div className="App-content">
        <h1>Standard Terms of Service</h1>
        <div className="PrivacyPolicy">
        
        <h3>Introduction</h3>
        <p>These Terms of Service ("<b>Terms</b>"), including our <a href="/privacy">Privacy Policy</a>, 
        govern your access to and use of our website: https://zmeus.com (herein referred to as the "<b>Site</b>"), it's content, 
        or API Software service(s) (collectively the "<b>Service(s)</b>") provided by Donaq, LLC, doing business as <b>Zmeus</b>
        (herein the "<b>Company</b>", "<b>Zmeus</b>", "we", "our" or "us"). By accessing and using the Services, visitors, users, 
        potential client or actual client (herein "<b>Client</b>", "you", "your", or "user") agree these Terms govern your access 
        to the Site and license to use the Services provided therein. If you are using the Services on behalf of an organization 
        or entity ("<b>Organization</b>"), you are agreeing to these Terms on behalf of that Organization and you represent and warrant 
        that you have authority to bind the Organization to these Terms. In such cases, "you" and "your" refers to you and that Organization. 
         In the event of any conflict between the Terms and any separate executed agreement with the Company, the Terms and 
         conditions of the separate agreement will prevail and govern.</p>
        <h3>Your Use of the Services</h3>
        <p>You are authorized to access and use the Services only in compliance with these Terms and all applicable local, state, 
        national, and international laws, rules, and regulations. Donaq, LLC reserves the right to refine and change these Terms from
        time to time without prior notice to you. Our Services provide an ability for you to manage and obtain federated access to 
        one or multiple AWS Accounts, either on Your behalf, or on behalf of third party Clients who have prviously granted You
        access to their accounts. You are responsible to obtain written permission from the owners of any accounts you access and 
        must only do so in accordance with any further restrictions the account owners have placed on you. Unauthorized access of
        third party accounts is punishable to the full extent of the law.  
        Any Client Data you submit must be adequate and relevant but not excessive to performing the account access purposes. 
        The Site, its Content, and software are not available or licensed to competitors of the Company. 
        Furthermore, by utilizing the Site or Services, you hereby certify that you are not a competitor of the Company and that 
        you will not share or otherwise disclose the Site, Software, or Content with competing companies.</p>
        <h3>Your Account</h3>
        <p>All users and Clients must provide us accurate information and identification to subscribe and create an account. 
        Your account gives you access to the Services, features, and functionalities that we establish and maintain from time to time at our sole discretion.
        You may not use another user's account without expressed permission and without prior consent from the Company.</p>
        <h3>Private Security Credentials</h3>
        <p>Any and all private credentials and/or secret authentication tokens including passwords that you use to access the Site or our
        Software are personal to you. You should not share or disclose your private authentication information with anyone. Disclosure of your 
        security credentials and/or information obtained from this Site to any third-party, including those within your organization, 
        authorizes that third-party to act as an agent on your behalf. You are responsible for safeguarding your credentials, and you agree not 
        to allow any third-party to access our Site, Software, and/or Content using your credentials, unless specifically granted herein. 
        The Company recommends creating credentials that cannot be easily guessed. You agree to take sole responsibility for any activities or 
        actions under your credentials, whether or not you have authorized such activities or actions. You will immediately notify the Company and 
        change your credentials if any unauthorized use of your credentials is suspected.</p>
        <h3>Age Restrictions</h3>
        <p>The Company will only knowingly provide its services to parties that can lawfully enter into and form contracts under applicable law.
        The legal minimum age of a user may vary depending on laws in your jurisdiction. The Site, Software, Services and Content are not intended 
        for children under the age of 13. If you are at least 13 but under the age of 18, use of our Site, Software, and Content is permissible only 
        under the approval and supervision of a parent, legal guardian or an entity able to be bound by our terms of service.</p>
        <h3>Reservation of Rights</h3>
        <p>The Company reserves all rights not expressly granted to Client herein and Client will not limit Company in any way from developing, 
        using, licensing, distributing, modifying, or otherwise freely exploiting the Services, Platform, Client-Hosted Software, 
        or Documentation or any Updates, Upgrades, modifications, enhancements, improvements or derivative works thereof, or permitting 
        third-parties from so doing.</p>
        <p>The Company reserves the right to investigate and prosecute violations of any of the above prohibited uses which may involve
         cooperation with law enforcement authorities. You acknowledge that the Company has no obligation to monitor your access to or use of the Site, 
         Software, or Content, but has the right to do so to ensure your compliance with these Terms, to comply with applicable laws, or to comply with
         a court order or requirement of an administrative agency or other governmental body.</p>
        <h3>External Links</h3>
        <p>The Company may provide links on our Site to other websites that may or may not be affiliated with, under the control of, or otherwise maintained
         by our Company. The Company does not control third parties, nor their services, and you assume all liabilities in connection to such services.
         The Company does not endorse nor make any representations or warranties about third-party sites or any information, software, or other products or services found there.</p>
        <h3>Term of Commencement</h3>
        <p>This Agreement shall commence on the date these terms are accepted by the Client through a click-through website subscription or an executed
        signature process and shall continue in intervals of one (1) month for as long as the Client continues to use Our services. 
        Services shall automatically be renewed so as to allow uninterrupted Services to Client for successive time periods until such time termination is requested or required by either Party.</p>
        <h3>NO WARRANTY. Use at Your Own Risk</h3>
        <p>THE SITE, CONTENT, AND ACCOUNTS, INCLUDING TRIAL ACCOUNTS, AND SERVICES DELIVERED BY THE COMPANY ARE PROVIDED "AS IS" AND "AS AVAILABLE" WITHOUT ANY WARRANTY OF ANY KIND
        EITHER EXPRESSED OR IMPLIED. THE COMPANY HEREBY DISCLAIMS ALL WARRANTIES, WHETHER EXPRESSED, IMPLIED, STATUTORY OR OTHERWISE (INCLUDING ALL WARRANTIES ARISING FROM COURSE 
        OF DEALING, USAGE OR TRADE PRACTICE), AND SPECIFICALLY DISCLAIMS ALL IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND NON-INFRINGEMENT.</p>
        <p>WHILE TAKING MEASURES TO ENSURE REASONABLE ACCURACY, SECURITY, AND AVAILABILITY OF THE SOFTWARE, THE COMPANY MAKES NO WARRANTY THAT THE SITE, SOFTWARE, OR CONTENT WILL 
        MEET YOUR REQUIREMENTS OR BE AVAILABLE ON AN UNINTERRUPTED, SECURE, OR ERROR-FREE BASIS. THE COMPANY MAKES NO WARRANTY REGARDING THE ACCURACY, TIMELINESS, TRUTHFULNESS, COMPLETENESS,
        OR RELIABILITY OF ANY DATA OR INFORMATION OBTAINED THROUGH THE SITE, SOFTWARE, OR CONTENT. YOUR ACCESS TO AND USE OF THE SITE, SOFTWARE, AND CONTENT IS AT YOUR OWN RISK. THE COMPANY 
        WILL HAVE NO RESPONSIBILITY FOR ANY HARM TO YOUR COMPUTER SYSTEM, LOSS OF DATA, OR OTHER HARM THAT RESULTS FROM YOUR ACCESS TO OR USE OF THE SITE, SOFTWARE, OR CONTENT.</p>
        <h3>Suspension and Termination</h3>
        <p>If you violate any of our Terms of Services, your authorization to use the Site, Software, Services and Content, may automatically suspend or terminate. 
        In the event of a termination due to your breach of our Terms, you agree that any fees paid for our services are non-refundable. This Agreement may be terminated by either Party: 
        (i) if the other Party fails to observe or perform any material term or condition of this Agreement and does not cure such breach or failure within thirty (30) days after written, 
        emailed, or direct notice by the other Party; or (ii) if the other Party makes a general assignment for the benefit of creditors or files a voluntary petition in bankruptcy or 
        if a petition in bankruptcy is filed against such other Party and is not dismissed within thirty (30) days after the filing, or if a receiver or trustee is appointed for all or any 
        part of the property or assets of such other Party. 
        You may terminate this Agreement for any reason, or no reason at all, by providing Company with thirty (30) days prior written notice.
        The Terms related to confidentiality, limitation of liability, indemnity, protection of Personal Information, dispute resolution, data ownership, any warranties offered herein, 
        including this clause shall survive the termination or expiration of this Agreement.</p>
        <h3>Abuse Alert</h3>
        <p>REGARDLESS OF ANY RIGHT TO CURE BREACH PROVISION STATED WITHIN THIS AGREEMENT, THE COMPANY AND RESPECTIVE SUPPLIERS AND LICENSORS RESERVE THE RIGHT TO IMMEDIATELY SUSPEND AND/OR 
        TERMINATE SERVICES TO CLIENTS THAT TRIGGER AN EMBEDDED ABUSE OR MISUSE DETECTION ALERT. SUCH ALERT INDICATES REVERSE ENGINEERING, DATA SOURCE SCRAPING, IMPROPER ACCESSING OR SHARING, 
        OR VIOLATING USAGE OF PROPRIETARY DATA WHICH, IN GENERAL, CANNOT BE TRIGGERED INADVERTENTLY AND WHICH CONSTITUTES A MATERIAL BREACH OF THIS AGREEMENT. THE ALERT WILL SUBJECT CLIENT TO 
        AN AUTOMATIC SUSPENSION OF ACCOUNT WHILE AN INVESTIGATION IS ENSUED. COMPANY WILL PROMPTLY NOTIFY CLIENT TO DETERMINE WHETHER A REINSTATEMENT OR TERMINATION OF SERVICES IS WARRANTED. 
        IN THE EVENT OF AN AUTOMATIC TERMINATION DUE TO AN ABUSE ALERT, CLIENT AGREES TO MAKE PAYMENT OF ANY OUTSTANDING FEES DUE AND WAIVES ANY BREACH CLAIMS UNDER THIS AGREEMENT</p>
        <h3>Indemnity</h3>
        <p>You agree to defend, indemnify, and hold harmless the Company, its officers, directors, employees, agents, and affiliates from and against any and all third-party claims, liabilities,
         damages, losses, and expenses (including reasonable legal and accounting or professional fees), arising out of your breach or violation of our Terms.</p>
        <h3>Controlling Law and Jurisdiction</h3>
        <p>These Terms and any action related thereto (including taxation issues) will be governed by the laws of the State of California without giving effect to any choice 
        or conflict of law provision or rule that would require or permit the application of the Laws of any jurisdiction other than those of the State of California. 
        The exclusive jurisdiction and venue of any action arising under these Terms or related thereto will be the state and federal courts located in Orange County, State of California,
        and each of the parties hereto waives any objection to jurisdiction and venue in such courts. In any action to enforce this clause or this Agreement, the prevailing Party will 
        be entitled to costs and reasonable attorneys' fees.</p>

        </div>
      </div>
    </div>);
};

export default TermsPage;