import React from 'react';

const PrivacyPage = function() {
    return (<div>
      <div className="App-content">
        <h1>Privacy Policy</h1>
        <div className="PrivacyPolicy">
        
        <h3>Overview</h3>
        <p>This Privacy Policy governs and describes how Donaq, LLC doing business as Zmeus (herein the "Company," 
        also referred to as "We", "Us" or "Our" herein) may collect, use, and disclose information 
        that we obtain through or from all customers or users (also referred to as "You", "Your" or "Client") 
        regardless of nationality or location when visiting our website or using our service. 
        All personal information that we collect and maintain shall be subject to our Privacy Policy and current laws 
        in effect at the time of collection.</p>
        <p>If you have objections to our Privacy Policy, you should not access our Services until modified terms
        have been agreed to mutually through an addendum to our <a href="tos">Terms of Service</a>. 
        If you require special instructions for processing your data, need us to enter into a Data Processing Agreement (DPA), 
        or make modifications to legal terms to protect your privacy, contact us to discuss our Enterprise Solutions.</p>
        
        <h3>DEFINITIONS for the purposes of this Privacy Policy:</h3>
        <p><b>"Client Account Information"</b> is any information that identifies you as our client, including, 
            but not limited to, a name, email address, location information, Usage Data, account or an identification number,
            or online identifier.</p>
        <p><b>"Client Data"</b> means the information, and/or input data, including an address or any other Personal information
         contained therein, in any form or media, that is (i) submitted or entered into the Company's cloud based system 
         or Site by Client or on behalf of Client or its end users; (ii) accessed or used by Company in providing any
          Services to Client; or (iii) data and content collected, compiled, inferred, stored, cached, or derived directly
        or indirectly from any of the data and content described in subclauses (i) and (ii) herein.</p>
        "Consumer" means a natural person who is a California resident as defined by California Consumer Privacy Act ("CCPA").
        <p>"Data Controller" refers to you as the legal person or entity which alone or jointly with others determines the purposes and means of processing Personal Information. You are responsible and accountable for the use of Personal Information of Data Subjects and Consumers in your possession.
        </p>
        <p><b>"Data Processor"</b> refers to us as your Service Provider, who processes Personal Information only on your behalf or
         instruction as the Data Controller.</p>

        <p><b>"Data Subject"</b> refers to any person residing in the European Union ("EU") whose Personal Information is being 
        collected, held, or processed as defined under the General Data Protection Regulation ("GDPR").</p>

        <p><b>"Enhanced Data Privacy"</b> also commonly referred to as "incognito mode" is an optional feature in the Platform
         that Client may elect to purchase that prevents Client Data and or Personally Identifiable Information (PII) from ever
         being logged at the point of submission or Provider's APIs. Client Data submissions are accessed only momentarily in
         Random Access Memory (RAM), just long enough to process and deliver results back to Client. Upon completion of the
         process, any residual Client Data in the system's RAM is dumped or "garbage collected" and written over by subsequent
         transactions. Such processing methods render data deletion, destruction, correction, blocking, or exportation back
         to Client not feasible due to the nature of the transitory process.</p>

        <p><b>"Personal Information"</b> or <b>"Personal Identifiable Information"</b> is defined as any information that 
        identifies or can be used to identify, contact, or locate a person, Consumer or Data Subject to whom such information 
        pertains. (It may refer to Client Data such as the list of addresses of individuals you are seeking to validate or verify).</p>

        <p><b>"Services"</b> means subscription to or use of Company's software products, APIs, and/or Website.</p>

        <p><b>"Service Provider"</b> refers to us as the Data Processor analyzing Personal Information against our authoritative
         database to provide you the requested Services.</p>

        <p><b>"Share"</b> or <b>"Sell"</b> means selling, renting, releasing, disclosing, disseminating, making available, 
        transferring, or otherwise communicating orally, in writing, or by electronic or other means, a Data Subject's or 
        Consumer's Personal Information to another business or a third-party for monetary or other consideration.</p>

        <p><b>"Usage Data"</b> refers to data collected automatically when you access any of our services either through the use
         of the Service or generated from the infrastructure itself (for example, the duration of a website visit).</p>

        <h3>ROLES IN DATA PRIVACY</h3>

        <p>Donaq, doing business as Zmeus, is identified as a Service Provider or <b>"Data Processor."</b>&nbsp;
           When you submit a request, Client Data or Personal Information, in your possession or under your control to be verified,
           we will process such request upon your instructions as the <b>"Data Controller."</b></p>
        
        <h4>Your responsibilities</h4>
        
        <ul>
            <li>By using our Services, you authorize us to process your Client Data, on your behalf, in accordance with applicable
            laws and these Terms of Service.</li>
            <li>As Data Controller, you are responsible to have a lawful basis for requesting processing of any Client Data you submit to us.</li>
            <li>As Data Controller, you are responsible to maximize the security of your Client Data by not submitting unnecessary personal 
            information such as name, age, sex, etc., and you agree to indemnify us of privacy violation claims if you do.</li>
            <li>The Client Data you submit must be adequate and relevant but not excessive for the purpose of authentication and authorization
                to access third party systems (services We provide)</li>
            <li>Contact us if you need Enhanced Data Privacy or need us to enter into a Data Privacy Agreement (DPA) with you.</li>
        </ul>

        <h4>Our responsibilities</h4>
        <ul>
            <li>We pledge to collaborate with you to meet your compliance obligations.</li>
            <li>We will not Sell or Share your Client Data or Client Account Information with any non-affiliated third-party entities
                unless legally mandated by a court order.</li>
            <li>We minimize all security risks by encrypting all Client Data you submit to our API in transit and at rest.</li>
            <li>We maintain appropriate administrative and physical security measures to protect against unauthorized access or accidental
                or unlawful destruction, loss, alteration or disclosure of your data.</li>
            <li>We have expounded how we manage and protect your Client Data in our Approach to Security.</li>
        </ul>

      </div>
      </div>
    </div>);
};

export default PrivacyPage;





