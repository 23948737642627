import React, {userState} from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  NavLink,
  Link,
  Redirect,
  useRouteMatch,
  withRouter
} from "react-router-dom";
import logo from './logo.svg';
import './App.css';
import shajs from 'sha.js';
import PrivacyPage from './PrivacyPage';
import TermsPage from './TermsPage';
import Dropdown from './Dropdown';
import AccountRole from './AccountRole';

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => {
    console.log('Private route: ', props);
    return (
      fakeAuth.isAuthenticated() === true
        ? <Component {...props} />
        : <Redirect to={{
            pathname: '/login',
            state: { from: props.location }
        }} />);
    }
  } />
)

function App() {
  return (
    <Router>
      <div className="App">
        <nav className="AppNav">
          <div className="FlexLeft">
            <img src={logo} className="AppNav-logo" alt="zmeus" />
            <span className="AppNav-logo">zmeus.com</span>
            <ul>
              <li>
                <NavLink to="/" activeStyle={{ fontWeight: "bold" }} exact>Home</NavLink>
              </li>
              <li>
                <NavLink to="/about" activeStyle={{ fontWeight: "bold" }}>About</NavLink>
              </li>
            </ul>
          </div>
          <AuthButton/>
        </nav>

        <Switch>
          <Route path="/login" component={LoginPage}/>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/tos">
            <TermsPage />
          </Route>
          <Route path="/privacy">
            <PrivacyPage />
          </Route>
          <PrivateRoute path="/dashboards" component={Dashboards}/>
          <PrivateRoute path="/" component={HomePage}/>
        </Switch>
      </div>
    </Router>
  );
}


class HomePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      accountsLoaded: false,
      accounts: null
    };
  }

  loadAccounts = async () => {
    const accounts = await fetchAccounts();
    this.setState({accounts: accounts, accountsLoaded: true});
    return true;
  }

  getConsoleAccessLink = async (event, opts) => {
    let childWindow = window.open('', '_zmeus_' + opts.accountId);
    childWindow.document.write('loading ...');
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    console.log(opts.accountId + '/' + opts.roleName);
    let result = await fetchAccountConsoleLoginUrl({
      account: opts.accountId, 
      role: opts.roleName, 
      email: window.localStorage.getItem("auth.userEmail"),
      duration: parseInt(opts.duration, 10)
    });
    if (result && result.consoleSigninUrl) {
      childWindow.location.href = 'https://signin.aws.amazon.com/oauth?Action=logout&redirect_uri=aws.amazon.com';
      await delay(500);
      childWindow.location.href = result.consoleSigninUrl;
    } else {
      childWindow.document.write("\nThere was an error logging you in. Check logs");
      childWindow.close();
      // TODO: ideally, we would show a better error message here and not a system alert
      alert('There was an error logging you in: check logs'); 
    }
  }

  getTempCredentials = async (event, opts) => {
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    console.log(opts.accountId + '/' + opts.roleName);
    let result = await fetchAccountAccessCredentials({
      account: opts.accountId, 
      role: opts.roleName, 
      email: window.localStorage.getItem("auth.userEmail"),
      duration: parseInt(opts.duration, 10)
    });
    if (result && result.credentials) {
      const now = new Date();
      const durationMs = parseInt(opts.duration, 10) * 1000;  // seconds * 1000 == milliseconds
      const offsetMs = now.getTimezoneOffset() * 60 * 1000;
      const dateLocal = new Date(now.getTime() + durationMs - offsetMs);
      const dateString = dateLocal.toISOString().slice(0, 19).replace(/-/g, "/").replace("T", " ");
      this.setState({tempCredentials: {
        account: opts.accountId,
        role: opts.roleName,
        accessKeyId: result.credentials.AccessKeyId,
        secretAccessKey: result.credentials.SecretAccessKey,
        sessionToken: result.credentials.SessionToken,
        expiration: dateString
      }})
    } else {
      // TODO: ideally, we would show a better error message here and not a system alert
      alert('There was an error getting temporary credentials: check logs'); 
    }
  }

  componentDidMount = async() => {
    let component = this;
    console.log('page component loaded; accountsLoaded: ' + component.state.accountsLoaded);
    if (!component.state.accountsLoaded) {
      const accounts = await fetchAccounts();
      component.setState({accounts: accounts, accountsLoaded: true});
    } 
  }

  render() {
    return (<div>
      <div className="App-content">
        <a onClick={this.loadAccounts} className="RefreshAccountsLink" href="#">
          <span className="RefreshAccountsSpan">refresh accounts</span>
        </a>
        <div className="AccountList">
          {this.state.accounts &&
            this.state.accounts.map((account, index) => {
              return (
                <div className="AccountItem" key={account.id}>
                  <h2>{account.name}</h2>

                  <div className="Details">
                    <p>id: <span>{account.id}</span> <a href={"/dashboards/" + account.id} target={"dashboard_" + account.id}>📈</a></p>
                    {account.roles.length > 0 &&
                      <>
                      <p>roles:</p><ul>{account.roles.map(r => {
                          return (<AccountRole 
                                accountId={account.id}
                                roleName={r.name}
                                role={r}
                                getConsoleHandler={(event, opts) => this.getConsoleAccessLink(event, opts)}
                                getCreadentialsHandler={(event, opts) => this.getTempCredentials(event, opts)}
                              />); 
                        })}</ul>
                      </>}
                    
                    {
                      this.state.tempCredentials && this.state.tempCredentials.account === account.id ? 
                      (<div className="TempCredentials">
                        <p>Temporary Credentials ({this.state.tempCredentials.role}@{this.state.tempCredentials.account})</p>
                        <p>credentials valid until {this.state.tempCredentials.expiration}</p>
                        <blockquote><pre><code>
                          echo {this.state.tempCredentials.role}@{this.state.tempCredentials.account}{"\n"}
                          export AWS_ACCESS_KEY_ID={this.state.tempCredentials.accessKeyId}{"\n"}
                          export AWS_SECRET_ACCESS_KEY={this.state.tempCredentials.secretAccessKey}{"\n"}
                          export AWS_SESSION_TOKEN={this.state.tempCredentials.sessionToken}{"\n"}
                          aws sts get-caller-identity{"\n"}
                          </code></pre>
                        </blockquote>
                      </div>) 
                      : 
                      (<div></div>)
                    }
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>);
  }
}

function Dashboards() {
    let match = useRouteMatch();
    return (<div>
      <div className="App-content">
      <Switch>
        <PrivateRoute path={`${match.path}/:accountId`} component={RoutedDashboard}/>
        <Route path={match.path}>
          <h3>Please select an account</h3>
        </Route>
      </Switch>
      </div>
  </div>);
}

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      accountId: props.match.params.accountId,
      loadingData: false,
      graphsLoaded: false,
      error: false
    };
  }

  componentDidMount = async() => {
    let component = this;
    console.log('dashboard component loaded; dashboardsLoaded: ' + component.state.graphsLoaded);
    if (!component.state.graphsLoaded && !component.state.loadingData && !component.state.error) {
      component.setState({loadingData: true});
      const graphNames = await fetchAccountDashboards(component.state.accountId);
      if (graphNames && graphNames.length > 0) {
        let graphs = [];
        for (let i = 0; i < graphNames.length; i++) {
          let graphName = graphNames[i];
          graphs.push({
            name: graphName,
            image: await fetchGraph(component.state.accountId, graphName)
          });
        }
        component.setState({graphs: graphs, graphsLoaded: true, loadingData: false});
      } else {
        component.setState({error: true, graphs: null, graphsLoaded: false, loadingData: false});
      }
    } 
  }

  render() {
    let { accountId } = this.props.match.params;
    console.log('dashboard component rendering', this.state);
    if (this.state.error) {
      return (<div>
        <div className="App-content">
          <h3>Requested Account ID: {this.state.accountId}</h3>
          <p>ERROR LOADING DATA</p>
        </div>
      </div>);
    } else if (this.state.loadingData) {
      return (<div>
        <div className="App-content">
          <h3>Requested Account ID: {this.state.accountId}</h3>
          <p>loading..</p>
        </div>
      </div>);
    } else {
    return (<div>
      <div className="App-content">
        <h3>Requested Account ID: {this.state.accountId}</h3>
        {this.state.graphs &&
            this.state.graphs.map((graph, index) => {
              return (
                <div key={graph.name}>
                  <p>{graph.name}</p>
                  <img src={"data:image/png;base64, " + graph.image} alt="graph" />
                </div>
              );
          })
        }
      </div>
    </div>);
    }
  }
};

const RoutedDashboard = withRouter(Dashboard);

function About() {
  return <div>
    <header className="App-header">
      <img src={logo} className="App-logo" alt="logo" />
    </header>
    <div className="App-content">
      <p><Link to="/tos">Terms of Service</Link></p>
      <p><Link to="/privacy">Privacy Policy</Link></p>
    </div>
  </div>;
}

const AuthButton = withRouter(({ history }) => (
  fakeAuth.isAuthenticated()
    ? <p className="AuthButton FlexRight">
        Welcome {window.localStorage.getItem("auth.userEmail") || ""} <button onClick={() => {
          fakeAuth.signout(() => history.push('/'))
        }}>Sign out</button>
      </p>
    : <p className="AuthButton">You are not logged in.</p>
));


class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      tos: false,
      isFetching: false,
      requestComplete: false,
      email: window.localStorage.getItem("auth.userEmail") || '',
      attempts: 0,
      redirectToReferrer: false
    };
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handleTosCheck = this.handleTosCheck.bind(this);

    let queryParams = new URLSearchParams(window.location.search);
    if (queryParams.get("mode") === 'ze_auth') {
      let queryState = queryParams.get("state");
      let access_token = queryParams.get("access_token");
      if (access_token && window.localStorage.getItem("auth.randomState") === queryState) {
        window.localStorage.setItem("auth.authAccessToken", access_token);
        this.state.redirectToReferrer = true;
      }
    }
  }
  
  login = async () => {
    let callbackUrl = window.location.origin + "/login";
    if (this.props.location.state && this.props.location.state.from) {
      window.localStorage.setItem("auth.sourceUrl", this.props.location.state.from.pathname || '/');
    } else {
      window.localStorage.setItem("auth.sourceUrl", '/');
    }
    this.setState({isFetching: true});
    let success = await requestAccessToken(this.state.email, callbackUrl);
    if (success) {
      this.setState({requestComplete: true});
    }
  }

  handleEmailChange(event) {
    this.setState({email: event.target.value});
  }
  handleTosCheck(event) {
    let isChecked = event.target.checked;
    this.setState({ tos: isChecked });
  }

  render() {
    const { from } = this.props.location.state || { from: { pathname: '/' } }
    const { redirectToReferrer, isFetching, requestComplete } = this.state

    if (!isFetching && redirectToReferrer === true) {
      return <Redirect to={from} />
    }

    if (isFetching && requestComplete) {
      return (
        <div>
        <header className="App-header">
          <h1>Login</h1>
        </header>
        <div className="App-content">
          <p>Authorization request sent. Please check your email</p>
        </div>
      </div>
      )
    } else if (isFetching) {
      return (
        <div>
        <header className="App-header">
          <h1>Login</h1>
        </header>
        <div className="App-content">
          <p>authorizing..</p>
        </div>
      </div>
      )
    }

    return (
      <div>
      <header className="App-header">
        <h1>Login</h1>
      </header>
      <div className="App-content">
        <p>You must log in to access this site</p>
        <p>
          <span>Email:</span>
          <input type="text" value={this.state.email} onChange={this.handleEmailChange} className="LoginEmail"/>
        </p>
        <p>
          <input type="checkbox" onChange={(event) => this.handleTosCheck(event)} checked={this.state.tos}/>
          <span>I have read and agree to the <a href="/tos">Terms of Service</a> &nbsp; and <a href="/tos">Privacy Policy</a></span>
        </p>
        { this.state.tos ?
          (<button onClick={this.login} className="LoginButton">Log in</button>) 
          : 
          (<span></span>)
        }
      </div>
    </div>
    )
  }
}

const fakeAuth = {
  isAuthenticated: () => {
    console.log('checking if isAuthenticated.. ');
    //const currentEpoch = Math.round((new Date()).getTime() / 1000);
    //let authTokenExpiration = window.localStorage.getItem("auth.authTokenExpiration") || 0;
    //if (isNaN(authTokenExpiration)) {
    //  authTokenExpiration = 0;
    //}
    let access_token = window.localStorage.getItem("auth.authAccessToken");
    let refresh_code = window.localStorage.getItem("auth.authRefreshCode");
    const userEmail = window.localStorage.getItem("auth.userEmail");
    if (userEmail) {
      if (access_token) {
        if (refresh_code) {
          console.log('checking if isAuthenticated: true');
          return true;
        }
        console.log('checking if isAuthenticated: refresh_code missing');
        return false;
      }
      console.log('checking if isAuthenticated: access_token missing');
      return false;
    }
    console.log('checking if isAuthenticated: userEmail missing');
    return false;
  },
  signout(cb) {
    window.localStorage.removeItem("auth.userId");
    //window.localStorage.removeItem("auth.userEmail");
    window.localStorage.removeItem("auth.userName");
    window.localStorage.removeItem("auth.authRefreshCode");
    window.localStorage.removeItem("auth.authAccessToken");
    setTimeout(cb, 0);
  }
};

const toHexString = (byteArray) => {
  return Array.prototype.map.call(byteArray, function(byte) {
    return ('0' + (byte & 0xFF).toString(16)).slice(-2);
  }).join('');
};

const generateAuthState = () => {
  var cryptoObj = window.crypto || window.msCrypto; // for IE 11
  var array = cryptoObj.getRandomValues(new Uint8Array(16));
  let randomState = toHexString(array);
  window.localStorage.setItem("auth.randomState", randomState);
  return randomState;
};

const requestAccessToken = async (email, url) => {
  let retries = 3;
  while (retries > 0) {
    try {
      let randomState = window.localStorage.getItem("auth.randomState");
      if (!randomState) {
        randomState = generateAuthState();
        window.localStorage.setItem("auth.randomState", randomState);
      }
      const response = await fetch("https://2z6r0qc3il.execute-api.us-east-1.amazonaws.com/prod/login", {
        method: "POST",
        body: new URLSearchParams({
          'sid': randomState,
          'email': email,
          'url': url,
          'state': randomState
        }),
        headers: new Headers({
          'Content-type': 'application/x-www-form-urlencoded; charset=UTF-8'
        })
      });
      if (response.status === 200) {
        const loginResult = await response.json();
        if (loginResult.rc) {
          window.localStorage.setItem("auth.authRefreshCode", loginResult.rc);
          window.localStorage.setItem("auth.userEmail", email);
          // request successful -- now we wait for the callback
          return true;
        }
      } else if (response.status === 400) {
        console.log('Access token request was invalid. This request cannot be retried');
        return false;
      }
    } catch(e) {
      console.error('There was an error fetching federation accounts (attempt ' + (4 - retries) + ')', e);
    }
    // exponential delay
    await delay(300 * Math.pow(2, (3-retries)));
    retries--;
  }
  return false;
}


const refreshAccessToken = async () => {
  let retries = 3;
  while (retries > 0) {
    console.log('attempting to refresh access token (attempt ' + (4 - retries) + ')');
    try {
      const response = await fetch("https://2z6r0qc3il.execute-api.us-east-1.amazonaws.com/prod/login", {
        method: "POST",
        headers: new Headers({
          'Authorization': 'Bearer ' + window.localStorage.getItem("auth.authAccessToken")
        })
      });
      if (response.status === 200) {
        const loginResult = await response.json();
        if (loginResult.challenge_code) {
          // generate the cr by hashing the challenge_code and the rc
          const rc = window.localStorage.getItem("auth.authRefreshCode") || '';
          const cr = shajs('sha256').update(loginResult.challenge_code + rc).digest('base64');
          
          const crResponse = await fetch("https://2z6r0qc3il.execute-api.us-east-1.amazonaws.com/prod/login", {
            method: "POST",
            body: new URLSearchParams({
              'cr': loginResult.challenge_code + ';' + cr
            }),
            headers: new Headers({
              'Content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
              'Authorization': 'Bearer ' + window.localStorage.getItem("auth.authAccessToken")
            })
          });
          if (crResponse.status === 200) {
            const newAccessToken = (await crResponse.json()).access_token;
            window.localStorage.setItem("auth.authAccessToken", newAccessToken);
            return true;
          }
        }
      } else if (response.status === 400) {
        console.log('Access token could not be refreshed: need to login again');
        // need to refresh token, or re-authorize
        window.localStorage.removeItem("auth.authRefreshCode");
        window.localStorage.removeItem("auth.authAccessToken");
        // refresh the page to force the login flow to restart
        window.location.assign('/');
        return false;
      }
    } catch(e) {
      console.error('There was an error fetching federation accounts (attempt ' + (4 - retries) + ')', e);
    }
    // exponential delay
    await delay(300 * Math.pow(2, (3-retries)));
    retries--;
  }
  console.error('There was an error refreshing access token: request failed after all retries exhausted');
  return false;
}

const delay = (timeToWait) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => resolve(), timeToWait);
  });
};

const fetchAccounts = async () => {
  let retries = 3;
  while (retries > 0) {
    try {
      const response = await fetch("https://2z6r0qc3il.execute-api.us-east-1.amazonaws.com/prod/federation/accounts", {
        method: "GET",
        headers: new Headers({
          'Authorization': 'Bearer ' + window.localStorage.getItem("auth.authAccessToken")
        })
      });
      console.log("... awaiting fetch accounts response .. ");
    
      if (response.status === 200) {
        const result = await response.json();
        return result.accounts;
      } else if (response.status === 401) {
        console.log('request was unauthorized: attempting to refresh access token');
        if (!(await refreshAccessToken())) {
          console.log('There was an error fetching federation accounts. Request was unauthorized and access token expired');
          // permanent failure 
          return undefined;
        }
      }
    } catch(e) {
      console.error('There was an error fetching federation accounts', e);
    }
    // exponential delay
    await delay(300 * Math.pow(2, (3-retries)));
    retries--;
  }
  console.error('There was an error fetching federation accounts: request failed after all retries exhausted');
  return undefined;
}

const fetchAccountDashboards = async (accountId) => {
  let retries = 3;
  while (retries > 0) {
    try {
      const response = await fetch("https://2z6r0qc3il.execute-api.us-east-1.amazonaws.com/prod/cw/graph/" + accountId, {
        method: "GET",
        headers: new Headers({
          'Authorization': 'Bearer ' + window.localStorage.getItem("auth.authAccessToken")
        })
      });
      console.log("... awaiting fetch account dashboards .. ");
    
      if (response.status === 200) {
        const result = await response.json();
        return result.graphs;
      } else if (response.status === 401) {
        console.log('request was unauthorized: attempting to refresh access token');
        if (!(await refreshAccessToken())) {
          console.log('There was an error fetching account dashboards. Request was unauthorized and access token expired');
          // permanent failure 
          return undefined;
        }
      }
    } catch(e) {
      console.error('There was an error fetching account dashboards', e);
    }
    // exponential delay
    await delay(300 * Math.pow(2, (3-retries)));
    retries--;
  }
  console.error('There was an error fetching account dashboards: request failed after all retries exhausted');
  return undefined;
}

const fetchGraph = async (accountId, graphName) => {
  let retries = 3;
  while (retries > 0) {
    try {
      const response = await fetch("https://2z6r0qc3il.execute-api.us-east-1.amazonaws.com/prod/cw/graph/" + accountId + "/" + graphName, {
        method: "GET",
        headers: new Headers({
          'Authorization': 'Bearer ' + window.localStorage.getItem("auth.authAccessToken")
        })
      });
      console.log("... awaiting fetch graph .. ");
    
      if (response.status === 200) {
        const result = await response.json();
        return result.image;
      } else if (response.status === 401) {
        console.log('request was unauthorized: attempting to refresh access token');
        if (!(await refreshAccessToken())) {
          console.log('There was an error fetching graph. Request was unauthorized and access token expired');
          // permanent failure 
          return undefined;
        }
      }
    } catch(e) {
      console.error('There was an error fetching graph', e);
    }
    // exponential delay
    await delay(300 * Math.pow(2, (3-retries)));
    retries--;
  }
  console.error('There was an error fetching graph: request failed after all retries exhausted');
  return undefined;
}

const fetchAccountAccessCredentials = async (opts) => {
  let retries = 3;
  while (retries > 0) {
    try {
      const response = await fetch("https://2z6r0qc3il.execute-api.us-east-1.amazonaws.com/prod/federation/access/credentials", {
        method: "POST",
        body: new URLSearchParams({
          'account': opts.account, 
          'role': opts.role,
          'email': opts.email,
          'project': 'default',
          'duration': opts.duration
        }),
        headers: new Headers({
          'Content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
          'Authorization': 'Bearer ' + window.localStorage.getItem("auth.authAccessToken")
        })
      });
      console.log("... awaiting fetch account access credentials response .. ");
    
      if (response.status === 200) {
        const result = await response.json();
        return {
          credentials: result.credentials
        };
      } else if (response.status === 401) {
        console.log('request was unauthorized: attempting to refresh access token');
        if (!(await refreshAccessToken())) {
          console.log('There was an error fetching account access credentials. Request was unauthorized and access token expired');
          // permanent failure 
          return undefined;
        }
      } else if (response.status === 400) {
        if (response.body == "The requested session duration was not allowed") {
          console.error('Could not fetch credentials: ' + response.body);
          // TODO: We should be able to return a better response here than undefined so 
          //       the calling code could present a more reasonable error message to the user
          return undefined;
        }
        console.error('There was an error fetching account access credentials: request was invalid');
        return undefined;
      }
    } catch(e) {
      console.error('There was an error fetching account access credentials', e);
    }
    // exponential delay
    await delay(300 * Math.pow(2, (3-retries)));
    retries--;
  }
  console.error('There was an error fetching account access credentials: request failed after all retries exhausted');
  return undefined;
}

const fetchAccountConsoleLoginUrl = async (opts) => {
  let retries = 3;
  while (retries > 0) {
    try {
      const response = await fetch("https://2z6r0qc3il.execute-api.us-east-1.amazonaws.com/prod/federation/access/console", {
        method: "POST",
        body: new URLSearchParams({
          'account': opts.account, 
          'role': opts.role,
          'email': opts.email,
          'project': 'default',
          'duration': opts.duration
        }),
        headers: new Headers({
          'Content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
          'Authorization': 'Bearer ' + window.localStorage.getItem("auth.authAccessToken")
        })
      });
      console.log("... awaiting fetch account access console response .. ");
    
      if (response.status === 200) {
        const result = await response.json();
        console.log("got account access console signin url: " + result.consoleSigninUrl);
        return {
          consoleSigninUrl: result.consoleSigninUrl
        };
      } else if (response.status === 401) {
        console.log('request was unauthorized: attempting to refresh access token');
        if (!(await refreshAccessToken())) {
          console.log('There was an error fetching account access console. Request was unauthorized and access token expired');
          // permanent failure 
          return undefined;
        }
      } else if (response.status === 400) {
        console.error('There was an error fetching account access console: request was invalid');
        return undefined;
      }
    } catch(e) {
      console.error('There was an error fetching account access console', e);
    }
    // exponential delay
    await delay(300 * Math.pow(2, (3-retries)));
    retries--;
  }
  console.error('There was an error fetching account access console: request failed after all retries exhausted');
  return undefined;
}

export default App;
