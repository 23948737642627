import React from 'react';
import './Dropdown.css';

const Dropdown = ({ value, options, onChange }) => {
    return (
      <div class="dropdown dropdown-tiny dropdown-dark">
        <select value={value} onChange={onChange} class="dropdown-select">
          {options.map((option) => (
            <option value={option.value}>{option.label}</option>
          ))}
        </select>
      </div>
    );
  };

export default Dropdown;